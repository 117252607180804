<template>
    <uw-content title="客户管理" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.customerCreate.Open()">添加客户</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>
            
            <!-- 检索 -->
            <template slot="search">
                <el-input @input="TableSearch" v-model="search.number" clearable  size="mini" placeholder="客户编号"></el-input>
                <el-input @input="TableSearch" v-model="search.name" clearable  size="mini" placeholder="客户名称"></el-input>
                <crm-customer-type-select @onChange="TableSearch" v-model="search.type_id" clearable />
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <!-- 表内容 -->
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    @sort-change="TableSort"
                    height="100%">

                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号"></vxe-column>

                    <!-- 客户编号 -->
                    <vxe-column min-width="150" field="number" title="客户编号" sortable></vxe-column>

                    <!-- 客户名称 -->
                    <vxe-column min-width="250" field="name" title="客户名称" sortable>
                        <template #default="{ row }">
                            <el-link type="primary" icon="el-icon-office-building" @click="$refs.customerDrawer.Open(row.id)">{{ row.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 工作电话 -->
                    <vxe-column min-width="160" field="work_phone" title="工作电话">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-mobile-phone" v-if="row.work_phone">{{ row.work_phone }}</el-link>
                            <el-link :underline="false" icon="el-icon-mobile-phone" type="info" v-if="!row.type">未记录</el-link>
                        </template>
                    </vxe-column>

                    <!-- 客户种类 -->
                    <vxe-column min-width="120" field="type" title="客户种类" sortable>
                        <template #default="{ row }">
                            <el-link :underline="false" v-if="row.type">{{ row.type }}</el-link>
                            <el-link :underline="false" type="info" v-if="!row.type">未分类</el-link>
                        </template>
                    </vxe-column>

                    <!-- 所属员工 -->
                    <vxe-column min-width="90" field="user.name" title="所属员工">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-user" v-if="row.user">{{ row.user?.name }}</el-link>
                            <el-link :underline="false" icon="el-icon-user" type="info" v-if="!row.user">未分配</el-link>
                        </template>
                    </vxe-column>
                    
                    <!-- 区域或国家 -->
                    <vxe-column min-width="90" field="country" title="区域或国家">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-place" v-if="row.country">{{ row.country }}</el-link>
                            <el-link :underline="false" icon="el-icon-place" type="info" v-if="!row.country">未知</el-link>
                        </template>
                    </vxe-column>

                    <!-- 省份 -->
                    <vxe-column min-width="90" field="country_province" title="省份">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-place" v-if="row.country_province">{{ row.country_province }}</el-link>
                            <el-link :underline="false" icon="el-icon-place" type="info" v-if="!row.country_province">未知</el-link>
                        </template>
                    </vxe-column>

                    <!-- 城市 -->
                    <vxe-column min-width="90" field="country_province_city" title="城市">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-place" v-if="row.country_province_city">{{ row.country_province_city }}</el-link>
                            <el-link :underline="false" icon="el-icon-place" type="info" v-if="!row.country_province_city">未知</el-link>
                        </template>
                    </vxe-column>

                    <!-- 详细地址 -->
                    <vxe-column min-width="250" field="address" title="详细地址">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-place" v-if="row.address">{{ row.address }}</el-link>
                            <el-link :underline="false" icon="el-icon-place" type="info" v-if="!row.address">未知</el-link>
                        </template>
                    </vxe-column>

                    <!-- 操作 -->
                    <vxe-column width="100"  title="操作" align="center" fixed="right">
                        <template #default="{ row }">
                            <el-link @click.native.stop="$refs.customerUpdate.Open(row.id)" type="primary" class="w-margin-r-10">修改</el-link>
                            <el-link @click.native.stop="TableEventDelete(row.id)" type="danger">移除</el-link>
                        </template>
                    </vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>
            
        </uw-table>

        <!-- 组件 -->
        <crm-customer-create ref="customerCreate" @onChange="TableSearch()" />
        <crm-customer-update ref="customerUpdate" @onChange="TableSearch()" />
        <crm-customer-drawer ref="customerDrawer" @onChange="TableSearch()" />
        
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 加载状态
            loading: false,

            // 数据
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                number: null,
                name: null,
                type_id: null,
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {
        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/customer/search',
                {
                    page: this.page,
                    search: { ...this.search, ...{ contract_id: this.id } },
                    order: this.order,
                    append: ['type', 'user', 'created_user', 'updated_user']
                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 移除数据
        |--------------------------------------------------
        |
        */

        TableEventDelete(id) {
            this.$http.post('/9api/customer/delete', { id: id }).then(rps => {
                this.TableSearch()
            })
        }
    }
}
</script>